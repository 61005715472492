import React from "react"
import Layout from "../templates/Layout"
import { graphql } from "gatsby"
import Helmet from "react-helmet"
import Hero from "../slices/Hero"
import TwoColBlockList from "../slices/TwoColBlockList"
import ThreeColBlockList from "../slices/ThreeColBlockList"
import ThreeColGrid from "../slices/ThreeColGrid"

import "react-unique-key" ;

const IndexPage = ({ data }) => {

  return (
    <Layout page={data.prismicHomepage} useBanner>
      <Helmet>
        <script type="application/ld+json">{`
          { "@context": "https://schema.org",
            "@type": "Organization",
            "name": "DUDOS",
            "legalName" : "DUDOS Online Ltd",
            "url": "https://dudos.co.uk",
            "logo": "https://dudos.co.uk/dudos-logo-sqr.png",
            "foundingDate": "2012",
            "founders": [
            {
              "@type": "Person",
              "name": "Mark A Hext"
            }],
              "address": {
              "@type": "PostalAddress",
              "streetAddress": "19 Saint Dominics Mews",
              "addressLocality": "Bolton",
              "addressRegion": "Greater Manchester",
              "postalCode": "BL3 3NX",
              "addressCountry": "UK"
            },
              "contactPoint": {
              "@type": "ContactPoint",
              "contactType": "sales",
              "telephone": "[+44 (0)20 3920 9079]",
              "email": "mark@dudos.co.uk"
            }}
        `}</script>
      </Helmet>


      {data.prismicHomepage.data.body.map((item) => {

        switch(item.slice_type){
          case "hero":
            return (
              <Hero 
                key={item['unique_key']}
                primary={item.primary}
                items={item.items}
              />
            )

          case "two_col_block_list":
            return (
              <TwoColBlockList 
                key={item['unique_key']}
                primary={item.primary}
                items={item.items}
              />
            )

          case "three_col_block_list":
            return (
              <ThreeColBlockList 
                key={item['unique_key']}
                primary={item.primary}
                items={item.items}
              />
            )

          case "three_col_grid":
            return (
              <ThreeColGrid 
                key={item['unique_key']}
                primary={item.primary}
                items={item.items}
              />
          )

          default:
            return (
              <pre key={item['unique_key']}>{JSON.stringify(item, null, 4)}</pre>
            )
          }
      })}
    </Layout>
  )
}

export default IndexPage

export const query = graphql`
{
  prismicHomepage {
    data {
      body {
        __typename
        ... on PrismicHomepageBodyHero {
          id
          slice_type
          items {
            item_image {
              url
            }
            item_title {
              html
              text
            }
          }
          primary {
            bg_color
            text_align
            text_color
            title {
              html
            }
            body_text {
              html
            }
            cta {
              isBroken
              slug
            }
            image {
              alt
              localFile {
                childImageSharp {
                    fluid(quality: 100, maxWidth: 800) {
                    ...GatsbyImageSharpFluid_withWebp
                    }
                  }
              }
            }
          }
        }
        ... on PrismicHomepageBodyTwoColBlockList {
          id
          slice_type
          primary {
            bg_color
            text_color
            show_item_icons
            show_body_icons
            image {
              alt
              url
              dimensions {
                height
                width
              }
              localFile {
                childImageSharp {
                    fluid(quality: 100, maxWidth: 300) {
                    ...GatsbyImageSharpFluid_withWebp
                    }
                  }
              }
            }
            title {
              html
            }
            body_text {
              html
            }
            cta {
              isBroken
              slug
            }
          }
          items {
            item_text {
              html
            }
            item_icon {
              url
              alt
            }
            item_title {
              html
            }
          }
        }
        ... on PrismicHomepageBodyThreeColBlockList {
          id
          slice_type
          primary {
            bg_image_place
            text_color
            body_text {
              html
            }
            cta {
              slug
              isBroken
            }
            title {
              html
            }
            bg_color
            image {
              alt
              url
              localFile {
                childImageSharp {
                    fluid(quality: 100, maxWidth: 1280) {
                    ...GatsbyImageSharpFluid_withWebp
                    }
                  }
              }
            }
          }
          items {
            item_icon {
              url
              alt
            }
            item_text {
              html
            }
            item_title {
              html
            }
          }
        }
        
        ... on PrismicHomepageBodyThreeColGrid {
          id
          slice_type
          primary {
            bg_color
            body_text {
              html
            }
            cta {
              url
              slug
              isBroken
            }
            image {
              alt
              url
              dimensions {
                height
                width
              }
            }
            show_body_icons
            show_item_icons
            text_color
            title {
              html
              text
            }
          }
          items {
            item_icon {
              alt
              url
            }
            item_text {
              html
              text
            }
            item_title {
              html
              text
            }
          }
        }
      }
      name {
        html
        text
        raw
      }
      meta_title {
        text
      }
      meta_description {
        text
      }
    }
  }
}
`
